//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { TransactionModel } from '@/modules/transaction/transaction-model';
const { fields } = TransactionModel;
import moment from 'moment';

export default {
  name: 'app-transaction-view-page',
  props: ['id'],

  async created() {
    await this.doFind(this.id);
  },
  data() {
    return {
      model: null,
    }
  },
  computed: {
    ...mapGetters({
      record: 'transaction/view/record',
      loading: 'transaction/view/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    fields() {
      return fields;
    },
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
  },
  methods: {
    ...mapActions({
      doFind: 'transaction/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(record, fieldName) {
      if (fieldName == 'accountType' || fieldName == 'payerType' || fieldName == 'payeeType' || fieldName == 'paymentMethod' || fieldName == 'status' || fieldName == 'type' || fieldName == 'operation') {
        return this.presenterEnum(record, fieldName)
      }
      const val = TransactionModel.presenter(record, fieldName);
      switch (fieldName) {
        case 'description':
          return val ? val[this.currentLanguageCode] : 'ـــ'
        case 'amount':
          return record['type'] == 'debit' 
            ? `${val * -1} ${this.currentLanguageCode == 'ar' ? 'ريال سعودي' : 'SAR'}` 
            : `${val} ${this.currentLanguageCode == 'ar' ? 'ريال سعودي' : 'SAR'}` 
        default:
          return val ? val : ''
      }
    },
    presenterEnum(record, fieldName, key) {
      const options = this.fields[fieldName].options;
      if (key) {
        const val = options.filter(el => el.value == record[key])
        return val.length ? this.i18n(val[0].i18n) : 'ـــ';
      } else {
        const val = options.filter(el => el.value == record[fieldName])
        return val.length ? this.i18n(val[0].i18n) : 'ـــ';
      }
    },
    presenterMap(record, fieldName) {
      const val = TransactionModel.presenter(record, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(record, fieldName) {
      const val = record[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(record, fieldName) {
      const val = record[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(record, fieldName) {
      const val = record[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(record, fieldName) {
      const date = record[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
  },
};
